<template>
  <v-select
    v-model="selectedEvent"
    placeholder="Event"
    :options="options"
    label="name"
    :selectable="option => option.id"
  >
    <template #option="{ isGroupName, name, date }">
      <h5
        v-if="isGroupName"
        class="mb-0 font-weight-bold"
      >
        {{ name }}
      </h5>
      <span
        v-else
        class="ml-1"
      >
        {{ name }}
        <b-badge
          v-if="date"
          variant="secondary"
        >
          {{ date }}
        </b-badge>
      </span>
    </template>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import { BBadge } from "bootstrap-vue";
export default {
  components: {
    vSelect,
    BBadge,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object
    },
  },
  data() {
    return {
      selectedEvent: null,
    };
  },
  watch: {
    value() {
      this.selectedEvent = this.value;
    },
    selectedEvent(n) {
      if (n) {
        this.$emit("input", n);
      }
    },
  },
  created() {
    if (this.value) {
      this.selectedEvent = this.value;
    }
  },
};
</script>
