import { render, staticRenderFns } from "./Sent.vue?vue&type=template&id=3fc89084&scoped=true&"
import script from "./Sent.vue?vue&type=script&lang=js&"
export * from "./Sent.vue?vue&type=script&lang=js&"
import style0 from "./Sent.vue?vue&type=style&index=0&id=3fc89084&lang=scss&scoped=true&"
import style1 from "./Sent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc89084",
  null
  
)

export default component.exports