<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <feather-icon
              icon="XIcon"
              size="18"
              class="cursor-pointer close-button d-none"
              @click="$emit('close-left-sidebar')"
            />
            <action-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="
                $emit('update:shall-show-email-compose-modal', true);
                $emit('close-left-sidebar');
              "
            >
              {{ showEmailComposeButton }}
            </action-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-for="filter in emailFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{
                  filter.title
                }}</span>
              </b-list-group-item>
            </b-list-group>

            <b-list-group class="list-group-messages mt-4">
              <b-list-group-item
                v-for="tab in emailTabs"
                :key="tab.title + $route.path"
                :to="tab.route"
                :active="isDynamicRouteActive(tab.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="tab.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{
                  tab.title
                }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Labels -->
            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="label in emailLabel"
                :key="label.title + $route.path"
                :to="label.route"
                :active="isDynamicRouteActive(label.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${label.color}`"
                />
                <span>{{ label.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BButton, BListGroup, BListGroupItem } from "bootstrap-vue";
import { isDynamicRouteActive } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";
import ActionButton from "@/views/components/common/ActionButton.vue";


export default {
  directives: {
    Ripple,
  },
  components: {
    ActionButton,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showEmailComposeButton() {
      const labels = {
        "champion-program-invites": "Invite users",
        "champion-program-invite-templates": "Invite users",
        "champion-program-schedules": "Schedule email"
      };
      return labels[this.$route.name] ?? "Compose";
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const emailFilters = [
      {
        title: "Messages",
        icon: "MailIcon",
        route: { name: "champion-program-communications" },
      },
      {
        title: "Scheduled",
        icon: "ClockIcon",
        route: { name: "champion-program-schedules" },
      },
      {
        title: "Invites",
        icon: "EditIcon",
        route: { name: "champion-program-invites" },
      },
    ];

    const emailTabs = [
      {
        title: "Sent",
        icon: "SendIcon",
        route: { name: "champion-program-sent" },
      },
      {
        title: "Undelivered",
        icon: "AlertCircleIcon",
        route: { name: "champion-program-undelivered" },
      },
    ];

    const emailLabel = [];

    const resolveFilterBadgeColor = filter => {
      if (filter === "Draft") return "light-warning";
      if (filter === "Spam") return "light-danger";
      return "light-primary";
    };

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,
      resolveFilterBadgeColor,

      // Filter & Labels
      emailFilters,
      emailLabel,
      emailTabs,
    };
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 991px) {
  .form-group-compose {
    position: relative;
    .close-button {
      display: block !important;
      position: absolute;
      top: 10px;
      right: 5px;
      color: var(--aom-color-secondary);
    }
    .btn {
      margin-top: 10px;
    }
  }
}
</style>
