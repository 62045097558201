var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"reply-mail","visible":_vm.shallShowEmailComposeModal,"title":"Reply Email","modal-class":"modal-sticky","footer-class":"d-flex justify-content-between","body-class":"p-0","size":"lg","no-fade":"","hide-backdrop":"","static":"","no-enforce-focus":""},on:{"change":function (val) { return _vm.$emit('update:shall-show-email-compose-modal', val); }},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title"},[_vm._v(" Reply ")]),_c('div',{staticClass:"modal-actions"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MinusIcon"},on:{"click":function($event){return _vm.$emit('update:shall-show-email-compose-modal', false)}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":_vm.discardEmail}})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',[_c('action-button',{attrs:{"variant":"primary","right":""},on:{"click":_vm.sendEmail}},[_c('feather-icon',{staticClass:"mr-1 cursor-pointer",attrs:{"icon":"SendIcon"}}),(_vm.isUpdating)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isUpdating)?_c('span',[_vm._v("Sending...")]):_c('span',[_vm._v("Send")])],1)],1),_c('div',[_c('feather-icon',{staticClass:"ml-75 cursor-pointer",attrs:{"icon":"TrashIcon","size":"17"},on:{"click":_vm.discardEmail}})],1)]},proxy:true}])},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.pristine;
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendEmail)}}},[_c('div',{staticClass:"message-editor"},[_c('validation-provider',{ref:"emailBody",attrs:{"rules":"required","vid":"emailBody","name":"Email Body"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [(_vm.shallShowEmailComposeModal)?_c('aom-text-editor',{attrs:{"state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.composeData.body),callback:function ($$v) {_vm.$set(_vm.composeData, "body", $$v)},expression:"composeData.body"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }