<template>
  <b-modal
    id="reply-mail"
    :visible="shallShowEmailComposeModal"
    title="Reply Email"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    no-fade
    hide-backdrop
    static
    no-enforce-focus
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        Reply
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="$emit('update:shall-show-email-compose-modal', false)"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>
    
    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <action-button
          variant="primary"
          right
          @click="sendEmail"
        >
          <feather-icon
            icon="SendIcon"
            class="mr-1 cursor-pointer"
          />
          <b-spinner
            v-if="isUpdating"
            small
          />
          <span v-if="isUpdating">Sending...</span>
          <span v-else>Send</span>
        </action-button>
      </div>
    
      <!-- Footer: Right Content -->
      <div>
        <!-- Discard Compose -->
        <feather-icon
          icon="TrashIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>
    
    <!-- Modal: Body -->
    <validation-observer
      ref="observer"
      v-slot="{ pristine, invalid, handleSubmit }"
    >
      <b-form @submit.prevent="handleSubmit(sendEmail)">    
        <div class="message-editor">
          <validation-provider
            v-slot="validationContext"
            ref="emailBody"
            rules="required"
            vid="emailBody"
            name="Email Body"
          >
            <aom-text-editor
              v-if="shallShowEmailComposeModal"
              v-model="composeData.body"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
    
<script>
import ActionButton from "@/views/components/common/ActionButton.vue";
import {
  BFormInvalidFeedback, BForm, BButton, BSpinner
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { commsService }  from "@/services";
import AomTextEditor from '@aom-core/AoMTextEditor.vue';
import { TopicClass, topicChannel, topicTypes, recipientTypes } from '@models';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  getValidationState
} from "@/libs/utils";
//eslint-disable-next-line
import { required } from "@validations";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
  
  
export default {
  name: 'EmailReply',
  directives: {
    Ripple,
  },
  components: {
    ActionButton,
    BForm, 
    BButton,
    ValidationObserver,
    ValidationProvider,
    AomTextEditor,
    BFormInvalidFeedback,
    BSpinner
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    emailData: {
      type:Array,
      default: () => []
    },
    recipients: {
      type:Array,
      default: () => []
    },
    message: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      isLoadingGroups: false,
      isUpdating: false,
      emailToOptions: [],
      emailToGroupOptions: [],
      emailPlugins: [
        'advlist', 'autolink','lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor',
        'searchreplace', 'visualblocks', 'code',
        'insertdatetime', 'media', 'table', 'paste', 'wordcount'],
      composeData: {
        user: [],
        group:[],
        subject: undefined, 
        body: undefined
      }
    };
  },
  created() {
    this.$watch('shallShowEmailComposeModal', val => {
      if(!val) {
        this.composeData = {
          user: [],
          group:[],
          subject: undefined,
          body: undefined
        };
        this.$refs.observer.reset();
      }
    });
  },
  methods: {
    sendEmail() {
      this.$refs.observer.validate().then(async valid => {
        if(!valid) return;
        try {
          this.isUpdating = true;
          const programId = this.$route.params.id;
          const topicId = this.emailData[0].topic_id;
          if (Object.keys(this.message).length) {
            const message = this.getEmailReplyMessage(topicId);
            await commsService.postCreateTopic(programId, message);
          } else {
            const message = this.getEmailMessage();
            await commsService.createTopicMessage(programId, topicId, message);
          }
          
          this.$emit('update:shall-show-email-compose-modal', false);
          this.$emit('emailAdded', topicId);
          this.$toast(makeSuccessToast("Email sent."));
        } catch(e) {
          this.$toast(makeErrorToast("Email not sent please try again."));
          console.log(e);
        } finally {
          this.isUpdating = false;
        }
      });      
    },
    updateMessageBody(body) {
      this.composeData.body = body;
    },
    getEmailMessage() {
      const message = {
        subject: this.emailData[0].subject,
        body: this.composeData.body,
        channel_id: topicChannel.EMAIL,
        type_id: topicTypes.MESSAGE,
      };
      return message;
    },
    getEmailReplyMessage(topicId) {
      const message = new TopicClass({
        subject: 'Re:' + this.emailData[0].subject,
        body: this.composeData.body,
        primary_topic_id: topicId,
        channel_id: topicChannel.EMAIL,
        type_id: topicTypes.MESSAGE,
        recipients: [{
          recipient_id: this.message.sender_id,
          recipient_type: recipientTypes.USER,
          raw_email_address: this.message.user.email
        }],
      });
      return message;
    }
  },
  setup(_, { emit }) {
    const discardEmail = () => {
      emit('update:shall-show-email-compose-modal', false);
    };
    
    return {
      discardEmail,
      getValidationState
    };
  },
};
</script>
     