import { ref, computed, watch } from '@vue/composition-api';
import {
  eventTriggers,
  actionableEvent as defaultEvents,
} from "@/models";
import store from '@/store';
import { parseISO } from 'date-fns';

export default function useActionableEvent(inProgram = false) {
  const defaultEventsDisplay = [{ id: defaultEvents.CUSTOM_DATE, name: 'Custom Date' }];
  // Prepare min value for custom date
  const today = new Date();
  const minCustomDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  // Data
  const happenWhen = ref(undefined);
  const actionableEvent = ref(undefined);
  const period = ref(undefined);
  const duration = ref(undefined);
  const scheduledDate = ref(undefined);

  const appActionableEvents = computed(() => store.getters['app/appActionableEvents']);
  const appKeyDates = computed(() => store.getters['app/appKeyDates']);
  const defaultProgramActionableEvents = computed(() => store.getters['programs/defaultProgramActionableEvents']);
  const defaultProgramKeyDates = computed(() => store.getters['programs/defaultProgramKeyDates']);

  const showCustomDateField = computed(() => {
    return actionableEvent.value && actionableEvent.value.id === defaultEvents.CUSTOM_DATE;
  });
  const showPeriodField = computed(() => {
    const notOn = !happenWhen.value || happenWhen.value.id !== eventTriggers.ON;
    const isCustomDate = actionableEvent.value && actionableEvent.value.id === defaultEvents.CUSTOM_DATE;

    return notOn && !isCustomDate;
  });
  const showDurationField = computed(() => {
    const notOn = !happenWhen.value || happenWhen.value.id !== eventTriggers.ON;
    const isCustomDate = actionableEvent.value && actionableEvent.value.id === defaultEvents.CUSTOM_DATE;

    return notOn && !isCustomDate;
  });

  const eventsDisplay = computed(() => {
    if (!happenWhen.value) { return []; }
    const actionableEvents = inProgram ? defaultProgramActionableEvents.value : appActionableEvents.value;
    const keyDates = inProgram ? defaultProgramKeyDates.value : appKeyDates.value;
    // Remove key date which is set in the past
    const validKeyDates = keyDates.filter(keyDate => {
      if (keyDate.date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const date = parseISO(keyDate.rawDate.replace('Z', ''));
        return date.getTime() >= today.getTime();
      }
      return true;
    });
    // Only show group name when validKeyDates empty
    // Fix issue by new variables
    const programKeyDates = validKeyDates.length > 0 ?
      [
        {
          name: 'Program Key dates',
          isGroupName: true
        },
        ...validKeyDates,
      ] : [];
    switch (happenWhen.value.id) {
    case eventTriggers.BEFORE:
      return [...programKeyDates];
    case eventTriggers.ON:
      return [
        {
          name: 'Participant Actions',
          isGroupName: true
        },
        ...actionableEvents,
        {
          name: 'Program Key dates',
          isGroupName: true
        },
        ...validKeyDates,
        ...defaultEventsDisplay,
      ];
    case eventTriggers.AFTER:
      return [
        {
          name: 'Participant Actions',
          isGroupName: true
        },
        ...actionableEvents,
        ...programKeyDates
      ];
    default:
      return [];
    }
  });
  
  const eventsDisplayForChampion = computed(() => {
    if (!happenWhen.value) { return []; }
    const keyDates = inProgram ? defaultProgramKeyDates.value : appKeyDates.value;
    // Remove key date which is set in the past
    const validKeyDates = keyDates.filter(keyDate => {
      if (keyDate.date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const date = parseISO(keyDate.rawDate.replace('Z', ''));
        return date.getTime() >= today.getTime();
      }
      return true;
    });
    // Only show group name when validKeyDates empty
    // Fix issue by new variables
    const programKeyDates = validKeyDates.length > 0 ?
      [
        {
          name: 'Program Key dates',
          isGroupName: true
        },
        ...validKeyDates,
      ] : [];
    switch (happenWhen.value.id) {
    case eventTriggers.BEFORE:
      return [...programKeyDates];
    case eventTriggers.ON:
      return [
        {
          name: 'Program Key dates',
          isGroupName: true
        },
        ...validKeyDates,
        ...defaultEventsDisplay,
      ];
    case eventTriggers.AFTER:
      return [
        ...programKeyDates
      ];
    default:
      return [];
    }
  });

  watch(happenWhen, () => {
    actionableEvent.value = undefined;
    period.value = undefined;
    duration.value = undefined;
    scheduledDate.value = undefined;
  });

  return {
    happenWhen,
    actionableEvent,
    period,
    duration,
    scheduledDate,
    showCustomDateField,
    showPeriodField,
    showDurationField,
    eventsDisplay,
    minCustomDate,
    eventsDisplayForChampion
  };
}
