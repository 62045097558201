import { recipientTypes, userRoles } from '@/models';
import router from '@/router';
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from '@vue/composition-api';

export const isObject = obj => typeof obj === 'object' && obj !== null;

export const isToday = date => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)];

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info']);

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route);
  return resolvedRoute.path === router.currentRoute.path || resolvedRoute.name === router.currentRoute.name;
};

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy;
  const state = reactive({
    route: vm.$route,
  });

  watch(
    () => vm.$route,
    r => {
      state.route = r;
    },
  );

  return { ...toRefs(state), router: vm.$router };
};

export const isCommsBetweenProgramManagerAndParticipant = (topic, message) => {
  const topicCreatedByRoles = topic?.created_by?.user_roles ?? [];
  const messageSenderRoles = message?.user?.user_roles ?? [];
  const topicSenderIsAdminOrChampion = topicCreatedByRoles.filter(r => {
    return [userRoles.CHAMPION, userRoles.ADMIN].indexOf(r.role_id) !== -1;
  }).length > 0;
  const senderIsParticipant = !message.user || messageSenderRoles.filter(r => {
    return [userRoles.MENTEE, userRoles.MENTOR].indexOf(r.role_id) !== -1;
  }).length > 0;
  if ((topicSenderIsAdminOrChampion || recipientHasChampionOrAdmin(topic?.topic_recipients ?? [])) && senderIsParticipant) {
    return true;
  }
  return false;
};

export const senderIsChampionOrAdmin = roles => {
  return roles.find(r => {
    return [userRoles.CHAMPION, userRoles.ADMIN, userRoles.PROGRAM_ADMIN].indexOf(r.role_id) !== -1;
  }) !== undefined;
};

export const senderIsChampionCandidateOrProgramAdminCandidate = roles => {
  return roles.find(r => {
    return [userRoles.CHAMPION_CANDIDATE, userRoles.PROGRAM_ADMIN_CANDIDATE].indexOf(r.role_id) !== -1;
  }) !== undefined;
};

export const senderIsParticipant = roles => {
  return roles.find(r => {
    return [userRoles.MENTEE, userRoles.MENTOR].indexOf(r.role_id) !== -1;
  }) !== undefined;
};

export const recipientHasChampionOrAdmin = topicRecipients => {
  return topicRecipients.find(r => {
    if (r.recipient_type === recipientTypes.USER) {
      return r.recipient?.user_roles?.find(role => {
        return [userRoles.CHAMPION, userRoles.PROGRAM_ADMIN, userRoles.ADMIN].indexOf(role.role_id) !== -1;
      }) !== undefined;
    }
    return false;
  }) !== undefined;
};

;

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }