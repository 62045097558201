<template>
  <div class="email-app-details">
    <aom-skeleton-loader v-if="isLoading" />
    <!-- Email Header -->
    <div
      v-else
      class="email-detail-header"
    >
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4
          v-if="emailViewData[0] && emailViewData[0].subject"
          class="email-subject mb-0"
        >
          {{ emailViewData[0].subject }}
        </h4>
      </div>
    </div>
  
    <!-- Email Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >
      <!-- Action: Show Earlier Message -->
      <b-row
        v-if="!showWholeThread && emailViewData.replies && emailViewData.replies.length"
        class="mb-1"
      >
        <b-col cols="12">
          <b-link
            class="font-weight-bold"
            @click="showWholeThread = true"
          >
            View {{ emailViewData.replies.length }} Earlier Message{{ emailViewData.replies.length > 1 ? 's' : '' }}
          </b-link>
        </b-col>
      </b-row>
  
      <!-- Earlier Email Messages -->
      <template>
        <b-row
          v-for="thread in emailViewData.slice()"
          :key="thread.id"
        >
          <b-col cols="12">
            <email-message-card
              :message="thread"
              :recipients="recipients"
              :should-reply-to-current-topic="shouldReplyToCurrentTopic"
              @replyMessage="replyMessage"
            />
          </b-col>
        </b-row>
      </template>
      <!-- Email Actions: Reply or Forward -->
      <b-row v-if="shouldReplyToCurrentTopic">
        <b-col cols="12">
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                <feather-icon
                  icon="CornerUpLeftIcon"
                  size="18"
                  class="mr-1"
                />
                <b-link @click="shallShowEmailComposeModal = true;message = {};">
                  Reply
                </b-link>
              </h5>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
    <email-reply
      v-model="shallShowEmailComposeModal"
      :email-data="emailViewData"
      :message="message"
      :recipients="recipients"
      @emailAdded="$emit('emailAdded', emailViewData[0].topic_id)"
    />
  </div>
</template>
  
<script>
import {
  BRow, BCol, BCard, BLink,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { ref, watch } from '@vue/composition-api';
import useEmail from './useEmail';
import EmailMessageCard from './EmailMessageCard.vue';
import AomSkeletonLoader from '@aom-core/AomSkeletonLoader.vue';
import EmailReply from './EmailReply.vue';
import { recipientTypes } from '@/models';
import { senderIsParticipant } from '@/@core/utils/utils';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    VuePerfectScrollbar,
    EmailMessageCard,
    AomSkeletonLoader,
    EmailReply
  },
  props: {
    emailViewData: {
      type: Array,
      required: true,
      default: () => []
    }, 
    recipients: {
      type: Array,
      default: () => [],
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      shallShowEmailComposeModal: false,
      message: {}
    };
  },
  computed:{
    shouldReplyToCurrentTopic() {
      const topicRecipients= this.recipients[0]?.topic_recipients || [];
      return topicRecipients.length === 1 && (topicRecipients.filter(item => item.recipient_type === recipientTypes.USER)).length === 1 
       || senderIsParticipant(this.recipients[0]?.created_by?.user_roles || []);
    }
  },
  setup(props) {
    const { resolveLabelColor } = useEmail();
  
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };
  
    const showWholeThread = ref(false);
  
    watch(() => props.emailViewData.id, () => {
      showWholeThread.value = false;
    });
  
    return {
      perfectScrollbarSettings,
      showWholeThread,
      resolveLabelColor,
    };
  },
  methods: {
    replyMessage(message){
      this.message = message;
      this.shallShowEmailComposeModal = true;
    }
  }
};
</script>
  
  <style>
  
  </style>
  